export const defaults = {
    page_title: "Илан Шор | Ilan Sor - Телеграм.SITES",
    main_url: "https://tracker.shor-telegram.com/tracking202/redirect/go.php?acip=414",
    name: "Молдова News",
    tag: "@ilanshor",
    main_avatar_url: "/img/main.jpeg",
    parsers:[
      {
        name: "Илан Шор | Ilan Șor",
        parser: "SOR",
        tag: "@ilanshor",
        main_url: "https://tracker.shor-telegram.com/tracking202/redirect/go.php?acip=414",
        avatar_url: "/img/main.jpeg",
        contacts_phrase: "Непрочитанные сообщения",
        new_messages: 5
      },
      {
        name: "Shor: just the facts",
        parser: "SOR2",
        tag: "@jtf_shor",
        main_url: "https://tracker.shor-telegram.com/tracking202/redirect/go.php?acip=227",
        avatar_url: "/img/secondary.jpeg",
        contacts_phrase: "Непрочитанные сообщения",
        new_messages: 8
      }
    ],
    posts: []
  }
